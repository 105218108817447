'use client';

import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import LoggerService from '@/services/isomorphic/LoggerService';
import { useContext, useRef, type FC } from 'react';
import { SkipRenderOnServer } from '@/react/components/utils/SkipRenderOnServer';
import { SkipRenderOnClient } from '../../../../utils/SkipRenderOnClient';
import { BreakpointGroupError } from '../../BreakpointGroupError';
import { BreakpointSizeContext } from '../BreakpointSizeContext';
import type { IInternalBreakpointGroupProps } from '.';
import type { BreakpointSize } from '../..';
import type { BreakpointConfigWithNode } from '../getBreakpointConfigs';

/**
 * A component that renders different UI based on the current breakpoint size.
 *
 * This "optimized" approach treats the breakpoint group as a single "tree",
 * where each `Breakpoint` is a glorified `if` statement. As a result, components
 * are not re-mounted unless React's reconciliation algorithm determines that they
 * need to be. While this behavior is more complex to implement, it is much more
 * performant and intuitive for developers.
 */
export const OptimizedBreakpointGroup: FC<IInternalBreakpointGroupProps> = ({
  configs
}) => {
  return (
    // On the server, render all configs to support all screen sizes.
    // After hydrating the client, render only the matching config.
    <SkipRenderOnServer fallback={<ConfigMatch all configs={configs} />}>
      <ConfigMatch configs={configs} />
    </SkipRenderOnServer>
  );
};

interface IConfigMatchProps {
  /** A list of breakpoint configurations for the group. */
  configs: Array<BreakpointConfigWithNode>;
  /** Whether to render all configs. This should only be set to true for SSR and hydration. */
  all?: boolean;
}

/**
 * A component for rendering the matching breakpoint configuration from the
 * current breakpoint size. If `all` is `true`, all configs will be rendered.
 *
 * Using some tricks, switching `all` to `false` will not cause a re-mount
 * of the remaining config.
 */
const ConfigMatch: FC<IConfigMatchProps> = ({ configs, all }) => {
  let currentSize = useContext(BreakpointSizeContext);
  const ref = useRef<number | null>(null);

  if (all) {
    // If `all` is `true`, render all configs.
    return configs.map(([config, node]) => (
      // When hydrating on the client, remove non-matching
      // configs and hydrate only the matching config.
      <SkipRenderOnClient
        key={config.id}
        className={config.className}
        shouldRenderOnClient={() =>
          config.matches.has(assertCurrentSize(currentSize))
        }
      >
        {node}
      </SkipRenderOnClient>
    ));
  }

  currentSize = assertCurrentSize(currentSize);
  const matchedConfig = configs.find(([config]) =>
    config.matches.has(currentSize)
  );

  if (!matchedConfig) return null;
  const [breakpointConfig, node] = matchedConfig;

  /**
   * While writing to refs during render isn't recommended,
   * it's generally safe if it's just to initialize the ref.
   *
   * Note: This also prevents the component from being memoized
   * by the React compiler, however there is not much to memoize
   * here anyway. Moreover, the compiler-compatible approach requires
   * an additional render, which would be more expensive than
   * what memoization would save.
   */
  if (ref.current === null) {
    /**
     * Using the config ID as the key below ensures that the
     * React reconciler will reuse the node from the list of
     * all rendered configs.
     *
     * By only assigning the ref once, the key below never
     * changes, enabling the reconciler to reuse nodes
     * between similar trees.
     */
    ref.current = breakpointConfig.id;
  }

  return (
    <SkipRenderOnClient
      key={ref.current}
      className={breakpointConfig.className}
      shouldRenderOnClient={() => true}
    >
      {node}
    </SkipRenderOnClient>
  );
};

/**
 * Asserts that the current size is not `null`.
 *
 * In prod environments, a `null` value will instead
 * log an error and return a default size of `md`.
 *
 * @param size - The current size.
 * @returns The current size.
 * @throws {@link BreakpointGroupError} If the size is `null` in non-prod environments.
 */
function assertCurrentSize(size: BreakpointSize | null): BreakpointSize {
  if (size !== null) return size;

  // If the current size is `null` on the client...
  const msg = 'BreakpointGroup rendered on the client with a null size.';

  // in production, log an error but continue with a medium size.
  if ((process.env.NEXT_PUBLIC_APP_ENV === "prod")) {
    LoggerService.error(msg + ' Falling back to "md" size.');
    return 'md';
  }

  // in non-production, throw an error for developer visibility.
  throw new BreakpointGroupError(
    msg + ' Did you forget to wrap your app in a BreakpointSizeProvider?'
  );
}
