/**
 * **Note:** The `Breakpoints` component is a "shared component", meaning it can be
 * used as a server component or a client component. While most shared components
 * can easily become client components via the `'use client';` directive, the
 * `Breakpoints` component **must** be shared. This is due to how the RSC protocol
 * serializes `children` nodes for client components, which doesn't preserve the
 * expected structure of the `Breakpoint` component. As a result, *do not* add
 * the `'use client';` directive to this file.
 */

import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import LoggerService from '@/services/isomorphic/LoggerService';
import {
  Children,
  isValidElement,
  type FC,
  type PropsWithChildren
} from 'react';
import { Breakpoint } from './Breakpoint';
import { BreakpointGroupError } from './BreakpointGroupError';
import {
  getBreakpointConfigs,
  type BreakpointElement
} from './internal/getBreakpointConfigs';
import { InternalBreakpointGroup } from './internal/InternalBreakpointGroup';

/**
 * A component for creating responsive UIs which can render different elements
 * based on the current breakpoint size, without skipping SSR or causing
 * hydration errors.
 *
 * @throws A {@link BreakpointGroupError} if any child is not a {@link Breakpoint}.
 * @throws A {@link BreakpointGroupError} the configurations do not cover all breakpoint sizes.
 * @see `breakpoints.md` guide for more information on how to use this component.
 * @example
 * <BreakpointGroup>
 *   <Breakpoint media="xxs:xs">
 *     <div>Content for extra small screens</div>
 *   </Breakpoint>
 *   <Breakpoint media="xl">
 *     <div>Content for extra large screens</div>
 *   </Breakpoint>
 *   <Breakpoint default>
 *     <div>Content for all other screens</div>
 *   </Breakpoint>
 * </BreakpointGroup>
 */
export const BreakpointGroup: FC<PropsWithChildren> = ({ children }) => {
  // eslint-disable-next-line @eslint-react/no-children-to-array -- We're doing some magic here.
  const childrenArray = Children.toArray(children);

  if (!(process.env.NEXT_PUBLIC_APP_ENV === "prod") && childrenArray.length === 1) {
    LoggerService.warn(
      `A BreakpointGroup has only one child. It should have at least two with non-overlapping breakpoint sizes.`
    );
  }

  const breakpointElements = childrenArray.filter(isBreakpointElement);
  if (breakpointElements.length !== childrenArray.length) {
    const msg =
      'All children of BreakpointGroup must be Breakpoint elements.' +
      ' Other nodes will be ignored.';

    if ((process.env.NEXT_PUBLIC_APP_ENV === "prod")) {
      LoggerService.error(msg);
    } else {
      throw new BreakpointGroupError(msg);
    }
  }

  const breakpointConfigs = getBreakpointConfigs(breakpointElements);
  return <InternalBreakpointGroup configs={breakpointConfigs} />;
};

/**
 * Checks if the given argument is a {@link Breakpoint} React element.
 * @param arg - The argument to check.
 * @returns `true` if the argument is a {@link Breakpoint} React element, `false` otherwise.
 */
function isBreakpointElement(arg: unknown): arg is BreakpointElement {
  return isValidElement(arg) && arg.type === Breakpoint;
}
