import type { IContent } from '@/services/isomorphic/ContentService';
import type { MaybePromise } from '@/type-utils';
import { use, type FC } from 'react';
import { RenderContent } from '../Content/RenderContent';

export interface IContentProps {
  /**
   * Content to render.
   */
  content: MaybePromise<IContent>;
}

/**
 * A component that abstracts over content rendering. It takes in content and renders
 * it as is.
 */
export const DirectContent: FC<IContentProps> = ({ content }) => {
  content = content instanceof Promise ? use(content) : content;
  return <RenderContent content={content} />;
};

export default DirectContent;
