import type { NonEmptyArray } from '@/type-utils';
import type { FC, PropsWithChildren } from 'react';
import type { BreakpointGroup } from './BreakpointGroup';
import type { BreakpointMediaKey } from './internal';

/** The base props for the {@link Breakpoint} component. */
interface IBreakpointBaseProps extends PropsWithChildren {
  /** A string or array of strings that specifies the size(s) this breakpoint matches. */
  media?: BreakpointMediaKey | NonEmptyArray<BreakpointMediaKey>;
  /** Specifies that this breakpoint should be used as the default when no other breakpoints match. */
  default?: boolean;
}

/** The props for the {@link Breakpoint} component when it is not the default breakpoint. */
export interface IBreakpointMediaProps extends IBreakpointBaseProps {
  /** @inheritdoc */
  media: BreakpointMediaKey | NonEmptyArray<BreakpointMediaKey>;
  /** @inheritdoc */
  default?: false;
}

/** The props for the {@link Breakpoint} component when it is the default breakpoint. */
export interface IDefaultBreakpointProps extends IBreakpointBaseProps {
  /** @inheritdoc */
  media?: undefined;
  /** @inheritdoc */
  default: true;
}

/** Props for the {@link Breakpoint} component. */
export type BreakpointProps = IBreakpointMediaProps | IDefaultBreakpointProps;

/**
 * A component which declares a breakpoint configuration to be used by {@link BreakpointGroup}.
 * It should either provide a `media` prop or be marked as `default`, but not both.
 * This component must **NOT** be used standalone.
 *
 * @throws A generic error if not a direct child of {@link BreakpointGroup}.
 * @example
 * <BreakpointGroup>
 *   <Breakpoint media="xxs:xs">
 *     <div>Content for extra small screens</div>
 *   </Breakpoint>
 *   <Breakpoint media="xl">
 *     <div>Content for extra large screens</div>
 *   </Breakpoint>
 *   <Breakpoint default>
 *     <div>Content for all other screens</div>
 *   </Breakpoint>
 * </BreakpointGroup>
 */
export const Breakpoint: FC<BreakpointProps> = () => {
  throw new Error(
    '<Breakpoint> should only be used as a direct child of <BreakpointGroup>.' +
      'It is not a standalone component, but rather a mechanism for configuring breakpoints within a <BreakpointGroup>.'
  );
};
