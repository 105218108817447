import {
  CMSService,
  type IContent
} from '@/services/isomorphic/ContentService';
import { FunctionComponent } from 'react';

import { exhaustiveGuard } from '@/utils/function-utils';
import { BuilderRender } from './BuilderRender';

export interface IContentProps {
  /**
   * Content to render.
   */
  content: IContent;
}

/**
 * A component that abstracts over content rendering. It takes in content and renders
 * it as is.
 */
export const RenderContent: FunctionComponent<IContentProps> = ({
  content
}) => {
  const { service } = content;
  switch (service) {
    case CMSService.Builder:
      return content.data && <BuilderRender content={content} />;
    default:
      return exhaustiveGuard(service, `Unknown content service: ${service}`);
  }
};

export default RenderContent;
