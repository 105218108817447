import type { BuilderContent } from '@builder.io/sdk';
import type { Nullable } from '@/type-utils';
import type { ModelTarget } from '../integrations/BuilderService';

/**
 * The content service used to retrieve content.
 */
export enum CMSService {
  /** This service is builder.io {@link BuilderService}. */
  Builder = 'builder'
}

/**
 * The content of the type of the selected service.
 */
export type IContent = IBuilderContent;

/**
 * The Builder content.
 */
export interface IBuilderContent {
  /**
   * The service used to retrieve the content.
   */
  service: CMSService.Builder;

  /** The type of content, like a page or a fragment. */
  type: ModelTarget;

  /**
   * The content of the type of the builder.io CMS.
   */
  data: Nullable<BuilderContent>;
}
